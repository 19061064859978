import React from "react";
import Header from "./components/header/Header";
import Nav from "./components/nav/Nav";
import About from "./components/about/About";
import Experience from "./components/experience/Experience";
import Services from "./components/services/Services";
import Portfolio from "./components/portfolio/Portfolio";
import Testimonials from "./components/testimonials/Testimonials";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import "./index.css";
import HeaderSocails from "./components/header/HeaderSocails";
import "./components/header/header.css";

const App = () => {
  return (
    <>
      <head>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1"
        ></meta>
      </head>
      {/* Extra tag to for the background image */}
      <section className="background_img">
        <HeaderSocails />
        <a href="#contact" className="scroll__down">
          Scroll Down
        </a>
        <Header />
        <Nav />
        <About />
        <Experience />
        <Services />
        <Portfolio />
        <Testimonials />
        <Contact />
        <Footer />
      </section>
    </>
  );
};

export default App;

import React from "react";
import "./about.css";
import ABOUT from "../../assets/ANA.jpg";
import { FiAward } from "react-icons/fi";
import { FiUsers } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";

const About = () => {
  return (
    <section id="about">
      <section className="about__header">
        <h5>Get to Know</h5>
        <h2>About me</h2>
      </section>
      <div className="container about__container">
        <div className="img__container">
          <div className="about__me-image">
            <img src={ABOUT} alt="About Me" />
          </div>
        </div>
        <div className="about__content">
          <p>
            Software Developer with experience across the full software
            development lifecycle with a strong foundation in UI/UX Design,
            frontend & backend, as well as web & mobile development.
            <br />
            <br />
            Creative Technologist with excellent problem-solving skills & the
            ability to function & thrive well independently & in fast-paced team
            environments offering an extensive background in Art & Design
            coupled with a pro-active dynamic approach to work.
            <br />
            <br />
            I'm also an Abstract Artist. You can find my work on{" "}
            <a href="https://www.instagram.com/anastasia.shtern">Instagram</a>.
          </p>
          <a href="#contact" className="btn btn-primary" style={{}}>
            Get in Touch
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;

import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {BsGithub} from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { IconContext } from "react-icons";


const HeaderSocails = () => {
  return (
    <div className="header__socials">
      <IconContext.Provider value={{ size: "1.25rem" }}>
        <a
          href="https://www.linkedin.com/in/anastasia-starostina-900bb2130"
          rel="noopener"
          target="_blank"
        >
          <BsLinkedin />
        </a>
      </IconContext.Provider>
      <IconContext.Provider value={{ size: "1.25rem" }}>
        <a
          href="https://github.com/nsstarr"
          rel="noopener"
          target="__blank"
        >
          <BsGithub />
        </a>
      </IconContext.Provider>
      <IconContext.Provider value={{ size: "1.25rem"}}>
        <a
          href="https://twitter.com/ShternAnastasia"
          rel="noopener"
          target="_blank"
        >
          <BsTwitter />
        </a>
      </IconContext.Provider>
    </div>
  );
}

export default HeaderSocails
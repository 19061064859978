import React from "react";
import "./portfolio.css";
import SQUATIFY from "../../assets/squatify.jfif";
import SAUNTER from "../../assets/saunter-screenshot.png";
import MINDACTIVITY from "../../assets/Mindactivity.jpg";
import HEROSJOURNEY from "../../assets/HerosJourney.jpg";
import QUIZ from "../../assets/quizhome.jpg";
import DESIGN from "../../assets/DesignSystems.jpg";
import RISING_SIGN from "../../assets/rising_sign.jpg";
import AIRBNB from "../../assets/airbnb.jpg";
import AUTH from "../../assets/auth_app.jpg";

const data = [
  {
    id: 0,
    image: AUTH,
    title: "Authorisation App",
    github: "https://github.com/nsstarr/auth_app",
    demo: "https://auth-app-nsstarr.netlify.app/",
    description:
      "Full-stack app with a login and registration page, and a dashboard that displays user data. The app uses a Node.js server with Express.js, and a Mondo DB database. On the frontend, the global state is managed with Redux.",
  },
  {
    id: 1,
    image: RISING_SIGN,
    title: "Daily Horoscope App",
    github: "https://github.com/nsstarr/react_rising_sign_horoscopes",
    demo: "https://risingsign.netlify.app/",
    description:
      "React-based web application that allows users to view free daily horoscopes based on their star sign. The app uses Twin Flame Horoscope API to fetch horoscope data and displays it in a user-friendly format.",
  },
  {
    id: 2,
    image: AIRBNB,
    title: "Airbnb Clone",
    github: "https://github.com/nsstarr/airbnb-clone",
    demo: "https://airbnb-clone-six-liart.vercel.app/",
    description:
      "Airbnb Clone with a calendar search functionality that displays available homes. The app utilises Server Side Rendering in Next JS and Tailwind CSS.",
  },

  {
    id: 3,
    image: SAUNTER,
    title: "Saunter App for Solo Travelers",
    github: "https://github.com/nsstarr/final-project_front-end-team-saunter",
    demo: "https://saunter-frontend.vercel.app/",
    description:
      "App to enable solo travellers to create saved day plans from a selection of attractions, from larger landmarks to smaller businesses",
  },
  {
    id: 4,
    image: MINDACTIVITY,
    title: "Mindfulness App",
    github: "https://github.com/nsstarr/Frontend-Mindactivity-App",
    demo: "https://mindactivity-app.netlify.app/",
    description:
      "Mindfulness App designed to manage stress levels and increase productivity.",
  },
];

const Portfolio = () => {
  return (
    <section id="portfolio">
      <section className="portfolio__header">
        <h5>My Recent Work</h5>
        <h2>Portfolio</h2>
      </section>
      <div className="portfolio__container">
        {data.map(({ id, image, title, github, demo, description }) => {
          return (
            <article key={id} className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={image} alt={title} className="portfolio_img"></img>
              </div>
              <h3>{title}</h3>
              <h5>{description}</h5>
              <div
                className="portfolio__item cta nohover"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <a href={github} className="btn">
                  Github
                </a>
                <a
                  href={demo}
                  className="btn btn-primary"
                  target="_blank"
                  rel="noreferrer"
                >
                  Live Demo
                </a>
              </div>
            </article>
          );
        })}
      </div>
      <br></br>
      <h5>
        See more on <a href="https://github.com/nsstarr">Github</a>.
      </h5>
    </section>
  );
};

export default Portfolio;

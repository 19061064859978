import React from "react";
import "./header.css";
import CTA from "./CTA";
import ME from "../../assets/ANA.jpg";
//Commented out the image while searching for a more suitable one
import HeaderSocails from "./HeaderSocails";

const Header = () => {
  return (
    <header>
      <HeaderSocails />
      <div className="container header__container">
        <h5>Hey, I am</h5>
        <h1>Anastasia</h1>
        <h5 className="text-light">Full Stack Software Developer.</h5>
        <CTA />
      </div>
    </header>
  );
};

export default Header;
